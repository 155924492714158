import React, {FC} from 'react';
import Section from '../../../components/section';
import {PageHero} from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import Discovertechstack from '../../../components/tools-api-requests/discover-tech-stack';
import Breadcrumb from '../../../components/breadcrumb';

const ApiDocumentationTechStack: FC = () => {
  return (
    <Layout>
       <PageHero title="" titleSmall minHeight id="seo_home_area_automations">
        <Breadcrumb
          items={[
            {
              to: `/automations`,
              name: 'Automations',
              active: false,
            },
            {
              to: `/automations/built-in-automations`,
              name: "Built-in automations",
              active: false,
            },
            {
              to: `/automations/research`,
              name: "Research",
              active: false,
            },
            {
              to: `/automation/discover-tech-stack/`,
              name: "Discover Tech Stack",
              active: false,
            },
            {
              to: `/automation/discover-tech-stack/api-documentation`,
              name: "API documentation",
              active: true,
            },
          ]}
        />
      </PageHero>
      <PageHero title="Discover Tech Stack API documentation" subtitle="This page provides API documentation for Discover Tech Stack" titleSmall minHeight id="seo_home_area_automations"></PageHero>
      <Section>
        <Discovertechstack />
      </Section>
    </Layout>
  );
};

export default ApiDocumentationTechStack;
